import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const lambo = document.getElementById('lambo');

let tl = gsap.timeline({
    scrollTrigger: {
        trigger: lambo,
        start: 'bottom bottom',
        // markers: true,
    },
});

tl.to(lambo, {
    x: 0,
    duration: 2,
    ease: 'power1.out',
});
